<template>
    <div class="app-container meeting-reservation">
        <div class="filter-line">
            <span>
                <el-button type="primary" icon="el-icon-plus" @click="addNewHandler">新增配置</el-button>
                <el-button @click="exportHandler">{{ this.$t("commons.export") }}</el-button>
                <el-button icon="el-icon-refresh-right" @click="getTableList()">{{ this.$t("commons.refresh") }}</el-button>
            </span>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" />
        </div>
        <!-- dialog -->
        <el-dialog :visible.sync="parkmeetingObj.dialogVisible" :before-close="handleClose" width="900px"
            :destroy-on-close="true" :close-on-click-modal="false">
            <template slot="title">
                <span>{{ parkmeetingObj.dialogTitle }}</span>
            </template>
            <meetingDialog v-if="parkmeetingObj.dialogVisible" :dialogStatus="parkmeetingObj.dialogStatus"
                :id="parkmeetingObj.id" @close="handleClose"></meetingDialog>
        </el-dialog>
    </div>
</template> 

<script>
import finalTable from "@/components/FinalTable";
import {
    getMeetingList,
    editInvoiceStatus,
    deleteMeetingActivity,
    meetingListExport
} from "@/api/ruge/jwPark/MeetingRoom";
import meetingDialog from "./meetingDialog.vue"
export default {
    components: {
        finalTable,
        meetingDialog
    },
    data() {
        return {
            parkmeetingObj: {
                dialogVisible: false,
                dialogTitle: "新增会议室配置",
                dialogStatus: "add",
            },
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                // selection: {
                //     need: true,
                //     width: "18",
                // },
                header: [
                    { prop: "createTime", label: "创建时间", width: "" },
                    { prop: "createBy", label: "创建人", width: "" },
                    { prop: "updateTime", label: "修改时间", width: "" },
                    { prop: "updateBy", label: "修改人员", width: "" },
                    { prop: "conferenceName", label: "会议室名称", width: "" },
                    { prop: "priceAStr", label: "1号价格位", width: "" },
                    { prop: "priceBStr", label: "2号价格位", width: "" },
                    { prop: "status", label: "是否上架", width: "" },
                    { prop: "operation", label: "操作", width: "100" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {},
                // 表格内容配置
                detailConfig: {
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "edit",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "编辑",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "delete",
                                fontSize: "14px",
                                color: "#909399 ",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "删除",
                            },
                        ],
                    },
                    status: {
                        type: "switch",
                        actionType: "switchEvent",
                    }
                },
                // 高级搜索配置
                advanceFilterConfig: {},
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },

            },
            suggestionObj: {
                tableLoading: false,
                dialogVisible: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    status: null
                }
            },
        }
    },
    created() {
        this.getTableList()
    },
    methods: {
        // 初始化数据
        getTableList() {
            this.suggestionObj.tableLoading = true;
            const params = { ...this.suggestionObj.listQuery };
            for (let pn in params) {
                !params[pn] && params[pn] !== 0 && (params[pn] = null);
            }
            getMeetingList(params)
                .then((res) => {
                    console.log(res, '列表数据');
                    res.data.rows.forEach((item) => {
                        item.status = item.status === 1 ? "ENABLE" : "DISABLE";
                    });
                    this.dataset.pageVO.total = res.data.total;
                    this.dataset.tableData = res.data.rows;
                })
                .finally(() => {
                    this.suggestionObj.tableLoading = false;
                });
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.suggestionObj.listQuery = {
                    ...this.suggestionObj.listQuery,
                    ...datas.params,
                };
                this.suggestionObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "paginationChange") {
                this.suggestionObj.listQuery.current = datas.params.current.page;
                this.suggestionObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "edit":
                        this.parkmeetingObj.id = datas.row.id;
                        this.parkmeetingObj.dialogStatus = "edit";
                        this.parkmeetingObj.dialogTitle = "编辑配置";
                        this.parkmeetingObj.dialogVisible = true;
                        break;
                    case "delete":
                        this.singleDeleteHandler(datas.row);
                }
            } else if (datas.type === "switchEvent") {
                console.log(datas.row, "datas.row");
                this.updateEnableStatus(datas.row);
            }
        },
        addNewHandler() {
            this.parkmeetingObj.dialogVisible = true
            this.parkmeetingObj.dialogStatus = "add"
            this.parkmeetingObj.dialogTitle = "新增会议室配置"
            this.parkmeetingObj.id = null;
        },
        handleClose(freshFlag) {
            console.log(111);
            this.parkmeetingObj.dialogVisible = false;
            freshFlag && this.getTableList();
        },
        // 是否已上架 按钮 状态配置
        updateEnableStatus({ id, status }) {
            console.log(id, status, '00');
            editInvoiceStatus({
                id: id,
                status: status == 'ENABLE' ? 1 : 0
            }).then((res) => {
                if (res) {
                    this.$message.success("修改状态成功！");
                    this.getTableList();
                }
            });
        },
        singleDeleteHandler(row) {
            this.deleteHandler(row.id);
        },
        deleteHandler(ids) {
            this.$confirm(
                "删除后将不可恢复，确定删除么？",
                this.$t("commons.warning"),
                {
                    confirmButtonText: this.$t("commons.confirm"),
                    cancelButtonText: this.$t("commons.cancel"),
                    type: "warning",
                }
            )
                .then(() => {
                    deleteMeetingActivity({
                        id: ids
                    }).then((res) => {
                        if (res.code === 200) {
                            this.$message.success("删除成功！");
                            this.getTableList();
                        } else {
                            this.$message.warning("删除失败！");
                        }
                    });
                })
                .catch((error) => {
                    console.log(`未删除，原因 => ${error}`);
                });
        },
        // 导出
        exportHandler() {
            meetingListExport(this.parkmeetingObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "会议预定",
                    taskStatus: 0,
                    rootPath: "customerGSPath",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
    },
    watch: {
        suggestionObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>


<style lang="less" scoped>
.meeting-reservation {
    .filter-line {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            height: 36px;
        }

        .icon-loudoutu {
            font-size: 12px;
        }

        .header-filter-button {
            cursor: pointer;
            width: 84px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .filter-button-open {
            color: #409eff;
            background: #ecf5ff;
            border: 1px solid #c6e2ff;
        }

        .filter-button-close {
            color: #2a4158;
            background: #ffffff;
            border: 1px solid #e7e8eb;
        }
    }
}
</style>