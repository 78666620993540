<template>
  <div class="park-meeting-dialog">
    <el-form
      v-if="page === 1"
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="会议室名称" prop="conferenceName">
            <el-input
              v-model="ruleForm.conferenceName"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="会议室描述" prop="description">
            <el-input
              v-model="ruleForm.description"
              placeholder="填写示例: 78m² | 可容纳200人 "
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="楼层选择" prop="floor">
            <el-select v-model="ruleForm.floor">
              <el-option
                v-for="item in floorList"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col>
          <div class="displayBooth">
            配置时间金额<span>至少配置1个价格展示位</span>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="8">
          <el-form-item label="一号价格展示位" prop="priceDisplayA">
            <el-input
              v-model="ruleForm.priceDisplayA"
              placeholder="填写示列：普通价格"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="1/小时" prop="priceA">
            <!-- <el-input v-model="ruleForm.priceA"></el-input> -->
            <div
              class="mo-input--number"
              style="width: 150px; display: inline-flex"
            >
              <el-input
                v-model="ruleForm.priceA"
                placeholder="输入金额"
              ></el-input>
              <div class="define-append">元</div>
            </div>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
                    <el-form-item label="半天4/小时" prop="priceB">
                        <div class="mo-input--number" style="width: 150px; display: inline-flex;">
                            <el-input v-model="ruleForm.priceB" placeholder="输入金额"></el-input>
                            <div class="define-append">元</div>
                        </div>
                    </el-form-item>
                </el-col> -->
      </el-row>
      <el-row :gutter="48">
        <el-col :span="8">
          <el-form-item label="二号价格展示位" prop="priceDisplayB">
            <el-input
              v-model="ruleForm.priceDisplayB"
              placeholder="填写示列：尊贵服务价格"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="1/小时" prop="priceC">
            <!-- <el-input v-model="ruleForm.priceC"></el-input> -->
            <div
              class="mo-input--number"
              style="width: 150px; display: inline-flex"
            >
              <el-input
                v-model="ruleForm.priceC"
                placeholder="输入金额"
              ></el-input>
              <div class="define-append">元</div>
            </div>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
                    <el-form-item label="半天4/小时" prop="priceD">
                        <div class="mo-input--number" style="width: 150px; display: inline-flex;">
                            <el-input v-model="ruleForm.priceD" placeholder="输入金额"></el-input>
                            <div class="define-append">元</div>
                        </div>
                    </el-form-item>
                </el-col> -->
      </el-row>
      <el-row :gutter="48">
        <el-col>
          <el-form-item label="上传图片" prop="photoIdListId">
            <div class="displayImgTip">
              温馨提示：最多上传6张，图片大小不能超过2m，图片格式为jpg，png。
              <br />图片尺寸 335*225像素
            </div>
            <div style="width: 80%">
              <el-upload
                :action="uploadPath"
                :limit="6"
                :on-success="handleAvatarSuccess"
                :file-list="fileList"
                :before-upload="beforeAvatarUpload"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item align="right">
        <el-button type="primary" @click="clickStep('next')">下一步</el-button>
      </el-form-item>
    </el-form>

    <el-form
      v-else
      :model="ruleForm1"
      :rules="rules1"
      label-position="top"
      ref="ruleForm1"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row :gutter="48">
        <el-col :span="24">
          <div class="serviceStyle">
            <el-form-item label="标准服务" prop="standardService">
              <div
                v-for="(item1, index) in ruleForm1.serviceList.standardService"
                :key="index"
                class="mo-input--number"
                style="width: 150px; display: inline-flex; margin: 10px"
              >
                <el-input
                  v-model="ruleForm1.serviceList.standardService[index]"
                  placeholder="输入内容"
                ></el-input>
                <div
                  class="define-append"
                  @click="deleteStandardService(1, index)"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
              <span class="serviceStyle-text" @click="addStandardService(1)"
                >添加内容</span
              >
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="serviceStyle">
            <el-form-item label="茶歇服务" prop="teaService">
              <div
                v-for="(item1, index) in ruleForm1.serviceList.teaService"
                :key="index"
                class="mo-input--number"
                style="width: 150px; display: inline-flex; margin: 10px"
              >
                <el-input
                  v-model="ruleForm1.serviceList.teaService[index]"
                  placeholder="输入内容"
                ></el-input>
                <div
                  class="define-append"
                  @click="deleteStandardService(2, index)"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
              <span class="serviceStyle-text" @click="addStandardService(2)"
                >添加内容</span
              >
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="serviceStyle">
            <el-form-item label="软硬件设施" prop="facilitiesService">
              <div
                v-for="(item1, index) in ruleForm1.serviceList
                  .facilitiesService"
                :key="index"
                class="mo-input--number"
                style="width: 150px; display: inline-flex; margin: 10px"
              >
                <el-input
                  v-model="ruleForm1.serviceList.facilitiesService[index]"
                  placeholder="输入内容"
                ></el-input>
                <div
                  class="define-append"
                  @click="deleteStandardService(3, index)"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
              <span class="serviceStyle-text" @click="addStandardService(3)"
                >添加内容</span
              >
            </el-form-item>
          </div>
        </el-col>
      </el-row>

      <el-form-item align="right" style="margin-top: 120px">
        <el-button type="primary" @click="clickStep('back')">上一步</el-button>
        <el-button type="primary" @click="clickStep('save')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import {
  addMeetingItem,
  getMeetingDetail,
  editParkActivity,
} from "@/api/ruge/jwPark/MeetingRoom";
export default {
  name: "meetingDialog",
  props: {
    dialogStatus: {
      type: String,
      required: true,
    },
    id: {
      required: false,
    },
  },
  data() {
    const validatorPhoto = (rule, value, callback) => {
      // if (this.currentState === "view") {
      //     callback();
      //     return;
      // }
      if (this.ruleForm.photoIdListId.length === 0) {
        callback(new Error("封面图片字段为必填"));
      } else {
        callback();
      }
    };
    const validatorpriceDisplayA = (rule, value, callback) => {
      // || this.ruleForm.priceB !== ''
      if (this.ruleForm.priceA !== "" && value === "") {
        callback(new Error("请填写一号价格展示位"));
      } else {
        callback();
      }
    };
    const validatorpriceA = (rule, value, callback) => {
      // || this.ruleForm.priceB !== ''
      if (this.ruleForm.priceDisplayA !== "" && value === "") {
        callback(new Error("请填写价格"));
      } else {
        callback();
      }
    };
    const validatorpriceB = (rule, value, callback) => {
      if (
        (this.ruleForm.priceDisplayA !== "" || this.ruleForm.priceA !== "") &&
        value === ""
      ) {
        callback(new Error("请填写价格"));
      } else {
        callback();
      }
    };
    const validatorpriceDisplayB = (rule, value, callback) => {
      // || this.ruleForm.priceD !== ''
      if (this.ruleForm.priceC !== "" && value === "") {
        callback(new Error("请填写二号价格展示位"));
      } else {
        callback();
      }
    };
    const validatorpriceC = (rule, value, callback) => {
      // || this.ruleForm.priceD !== ''
      if (this.ruleForm.priceDisplayB !== "" && value === "") {
        callback(new Error("请填写价格"));
      } else {
        callback();
      }
    };
    const validatorpriceD = (rule, value, callback) => {
      // this.ruleForm.priceD !== '' ||
      if (this.ruleForm.priceC !== "" && value === "") {
        callback(new Error("请填写价格"));
      } else {
        callback();
      }
    };

    const standardServiceList = (rule, value, callback) => {
      if (this.ruleForm1.serviceList.standardService !== "undefined") {
        let allNotEmpty = true;
        for (
          let i = 0;
          i < this.ruleForm1.serviceList.standardService.length;
          i++
        ) {
          if (this.ruleForm1.serviceList.standardService[i] === "") {
            allNotEmpty = false;
            break;
          }
        }
        if (!allNotEmpty) {
          callback(new Error("请填写内容"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    const teaServiceList = (rule, value, callback) => {
      if (this.ruleForm1.serviceList.teaService.length !== 0) {
        let allNotEmpty = true;
        for (let i = 0; i < this.ruleForm1.serviceList.teaService.length; i++) {
          if (this.ruleForm1.serviceList.teaService[i] === "") {
            allNotEmpty = false;
            break;
          }
        }
        if (!allNotEmpty) {
          callback(new Error("请填写内容"));
        } else {
          callback();
        }
      }
    };

    const facilitiesServiceList = (rule, value, callback) => {
      if (this.ruleForm1.serviceList.facilitiesService.length !== 0) {
        let allNotEmpty = true;
        for (
          let i = 0;
          i < this.ruleForm1.serviceList.facilitiesService.length;
          i++
        ) {
          if (this.ruleForm1.serviceList.facilitiesService[i] === "") {
            allNotEmpty = false;
            break;
          }
        }
        if (!allNotEmpty) {
          callback(new Error("请填写内容"));
        } else {
          callback();
        }
      }
    };
    return {
      editId: "",
      currentState: "add",
      page: 1,
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      floorList: [
        {
          value: "1",
          label: "17楼会议室",
        },
        {
          value: "2",
          label: "21楼东会议室",
        },
        {
          value: "3",
          label: "21楼西会议室",
        },
      ],
      ruleForm: {
        conferenceName: "",
        description: "",
        // 1号价格
        priceDisplayA: "",
        priceA: "",
        priceB: "",
        // 2号价格
        priceDisplayB: "",
        priceC: "",
        priceD: "",
        photoIdListId: [],
        floor: "",
      },
      rules: {
        conferenceName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        description: [{ required: true, message: "请输入", trigger: "blur" }],
        priceDisplayA: [
          {
            required: true,
            validator: validatorpriceDisplayA,
            trigger: "change",
          },
        ],
        priceA: [
          { required: true, validator: validatorpriceA, trigger: "change" },
        ],
        priceB: [
          { required: true, validator: validatorpriceB, trigger: "change" },
        ],
        priceDisplayB: [
          {
            required: true,
            validator: validatorpriceDisplayB,
            trigger: "change",
          },
        ],
        priceC: [
          { required: true, validator: validatorpriceC, trigger: "change" },
        ],
        priceD: [
          { required: true, validator: validatorpriceD, trigger: "change" },
        ],
        photoIdListId: [{ required: true, validator: validatorPhoto }],
        floor: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      ruleForm1: {
        serviceList: {
          standardService: ["", "", "", ""],
          teaService: ["", "", "", ""],
          facilitiesService: ["", "", "", "", "", "", "", ""],
        },
      },
      rules1: {
        standardService: [
          { required: true, validator: standardServiceList, trigger: "change" },
        ],
        teaService: [
          { required: true, validator: teaServiceList, trigger: "change" },
        ],
        facilitiesService: [
          {
            required: true,
            validator: facilitiesServiceList,
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    addStandardService(type) {
      if (type == 1) {
        this.ruleForm1.serviceList.standardService.push("");
      }
      if (type == 2) {
        this.ruleForm1.serviceList.teaService.push("");
      }
      if (type == 3) {
        this.ruleForm1.serviceList.facilitiesService.push("");
      }
    },
    deleteStandardService(type, index) {
      if (type == 1) {
        this.ruleForm1.serviceList.standardService.splice(index, 1);
      }
      if (type == 2) {
        this.ruleForm1.serviceList.teaService.splice(index, 1);
      }
      if (type == 3) {
        this.ruleForm1.serviceList.facilitiesService.splice(index, 1);
      }
    },
    clickStep(type) {
      if (type == "next") {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.page = 2;
          }
        });
        console.log(type);
      }
      if (type == "back") {
        this.page = 1;
      }
      if (type == "save") {
        console.log(111);
        this.$refs["ruleForm1"].validate((valid) => {
          if (valid) {
            console.log(222);
            let params = {
              conferenceName: this.ruleForm.conferenceName,
              description: this.ruleForm.description,
              priceDisplayA: this.ruleForm.priceDisplayA,
              priceA: this.ruleForm.priceA,
              priceB: this.ruleForm.priceB,
              priceDisplayB: this.ruleForm.priceDisplayB,
              priceC: this.ruleForm.priceC,
              priceD: this.ruleForm.priceD,
              resourceVOList: this.ruleForm.photoIdListId,
              floor: this.ruleForm.floor,
              serviceTypeVOMap: {
                1: this.ruleForm1.serviceList.standardService,
                2: this.ruleForm1.serviceList.teaService,
                3: this.ruleForm1.serviceList.facilitiesService,
              },
            };
            let editparams = {
              id: this.editId,
              conferenceName: this.ruleForm.conferenceName,
              description: this.ruleForm.description,
              priceDisplayA: this.ruleForm.priceDisplayA,
              priceA: this.ruleForm.priceA,
              priceB: this.ruleForm.priceB,
              priceDisplayB: this.ruleForm.priceDisplayB,
              priceC: this.ruleForm.priceC,
              priceD: this.ruleForm.priceD,
              resourceVOList: this.ruleForm.photoIdListId,
              floor: this.ruleForm.floor,
              serviceTypeVOMap: {
                1: this.ruleForm1.serviceList.standardService,
                2: this.ruleForm1.serviceList.teaService,
                3: this.ruleForm1.serviceList.facilitiesService,
              },
            };
            console.log(params);
            if (this.dialogStatus === "add") {
              addMeetingItem(params).then((res) => {
                console.log(res);
                if (res.code === 200) {
                  this.$message.success(res.data);
                  this.$emit("close", true);
                }
                if (res.code === 500) {
                  this.$message(res.msg);
                  // this.$emit("close", true);
                }
              });
            } else {
              console.log(editparams, "editparams");
              editParkActivity(editparams).then((res) => {
                if (res.code === 200) {
                  this.$message.success("修改成功");
                  this.$emit("close", true);
                }
                if (res.code === 500) {
                  this.$message(res.msg);
                  // this.$emit("close", true);
                }
              });
            }
          }
        });
      }
    },
    handleAvatarSuccess(res, file, fileList) {
      console.log(fileList, "fileList");
      let photoIdList = [];
      fileList.forEach((file) => {
        const fileId = file.response[0].fileId;
        const fileType = file.response[0].contentType;
        const resourceVOList = {
          fileId: fileId,
          fileType: fileType,
          thumbnailId: "",
        };
        photoIdList.push(resourceVOList);
      });
      this.fileList = photoIdList.map((item) => {
        return {
          url: `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.fileId}`,
          response: [{ fileId: item.fileId }],
        };
      });
      this.ruleForm.photoIdListId = photoIdList;
    },
    beforeAvatarUpload(file) {
      console.log("上传之前", file);
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      return returnFlag;
    },
    handleRemove(file, fileList) {
      console.log(fileList);
      let photoIdList = [];
      fileList.forEach((file) => {
        const fileId = file.response[0].fileId;
        const fileType = file.response[0].contentType;
        const resourceVOList = {
          fileId: fileId,
          fileType: fileType,
          thumbnailId: "",
        };
        photoIdList.push(resourceVOList);
      });
      this.fileList = photoIdList.map((item) => {
        return {
          url: `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.fileId}`,
          response: [{ fileId: item.fileId }],
        };
      });
      this.ruleForm.photoIdListId = photoIdList;
    },
    handlePictureCardPreview(file) {
      console.log(file, "file");
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getActivityDetail(id) {
      this.editId = id;
      getMeetingDetail({ id }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.ruleForm.conferenceName = res.data.conferenceName;
          this.ruleForm.description = res.data.description;
          this.ruleForm.priceDisplayA = res.data.priceDisplayA;
          this.ruleForm.priceA = res.data.priceA;
          this.ruleForm.priceB = res.data.priceB;
          this.ruleForm.priceDisplayB = res.data.priceDisplayB;
          this.ruleForm.priceC = res.data.priceC;
          this.ruleForm.priceD = res.data.priceD;
          this.ruleForm.floor = res.data.floor;
          // this.ruleForm.photoIdListId = res.data.priceD
          this.ruleForm1.serviceList.standardService =
            res.data.serviceTypeVOMap[1];
          this.ruleForm1.serviceList.teaService = res.data.serviceTypeVOMap[2];
          this.ruleForm1.serviceList.facilitiesService =
            res.data.serviceTypeVOMap[3];
          // url回显用
          // response 给编辑的时候remove时用
          let photoIdList = [];
          res.data.resourceVOList.forEach((res) => {
            const fileId = res.fileId;
            const fileType = res.fileType;
            const resourceVOList = {
              fileId: fileId,
              fileType: fileType,
              thumbnailId: "",
            };
            photoIdList.push(resourceVOList);
          });
          this.ruleForm.photoIdListId = photoIdList;

          this.fileList = res.data.resourceVOList.map((item) => {
            return {
              url: `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.fileId}`,
              response: [{ fileId: item.fileId }],
            };
          });
        }
      });
    },
  },
  watch: {
    id: {
      handler(id) {
        if (!id) return;
        this.getActivityDetail(id);
      },
      immediate: true,
    },
    dialogStatus: {
      handler(val) {
        this.currentState = val;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.park-meeting-dialog {
  .serviceStyle {
    border: 1px solid #f1f1f1;
    padding: 10px;
    margin-bottom: 30px;

    .serviceStyle-text {
      color: #1a4cec;
      font-size: 14px;
      text-align: right;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  /* 自定义数字输入框append  */
  .mo-input--number {
    border: 1px solid #dcdfe6;
    width: 100%;
    display: flex;
    border-radius: 4px;

    .el-input-number--mini {
      flex: 1;
    }

    ::v-deep .el-input__inner {
      border: none !important;
    }

    ::v-deep .el-input-number--medium {
      width: 110px;
    }

    .define-append {
      width: 40px;
      text-align: center;
      background-color: #f5f7fa;
      color: #909399;
    }
  }

  .displayBooth {
    margin: 24px 0;
    color: #606266;
    font-size: 14px;
    font-weight: 700;

    span {
      color: #909399;
      margin-left: 4px;
    }
  }

  .displayImgTip {
    font-size: 14px;
    color: #909399;
  }

  .avatar-uploader {
    border: 1px dotted #ccc;
    height: 178px;
    width: 178px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>